import { Link } from 'react-router-dom';
import Footer from '../component/Footer/Footer';

function ThankYouPage() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, textAlign: 'center' }}>
            <h2>Thank You for Signing Up!</h2>
            <br/>
            <h4 style={{ fontWeight: 'lighter' }}>We're thrilled to have your interest.</h4>
            <h4 style={{ fontWeight: 'lighter' }}>Our team will reach out to you shortly to help you get started.</h4>

            <button className="mb-5 text-uppercase f-13 btn bg-transparent"><Link to="/">Home page<span
                                        className="right-arrow ms-1">&#x21FE;</span></Link></button>
        </div>
        <Footer style={{ marginTop: 'auto'}}/>
    </div>

  );
}
export default ThankYouPage;