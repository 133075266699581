import Footer from '../Footer/Footer';
// import { Link } from 'react-router-dom';
import logo from '../../assets/images/lexim_logo.svg';
import { Row, Col } from 'reactstrap';
import { useEffect, useState } from 'react';

function TOC(){
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        fetch('TermsAgreement.htm')
          .then(response => response.text())
          .then(text => {
              const asciiHTML = text.replace(/�/g, "");
              setHtmlContent(asciiHTML);
          });
    }, []);

    return(
    <>
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', overflowX:'hidden'}}>
    <Row>
        <section className="navbar bg-black pt-3 pb-3">
        <Col lg={12} >
        <div className="f-13 bg-transparent" style={{ marginLeft: '60px' }}>
                <a href='/'><img className="bg-light" src={logo} alt="Home" height="50"></img></a>
                {/* <Link to="/" style={{marginTop:'2px'}} className='text-white'>LEXIM AI</Link> */}
        </div>                                   
        </Col>
        </section>
    </Row>
    <Row className="align-items-center mt-5 mx-5">
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </Row>
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1}}></div>
    <Footer style={{ marginTop: 'auto'}}/>
    </div>
    </>
    )
}
export default TOC;