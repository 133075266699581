import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom';

export default class FooterLink extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="footer-alt bg-dark pt-3 pb-3">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center d-flex flex-row justify-content-center align-items-center">
                                <p className='text-white f-14 fw-light mb-0 me-3'><Link to="/terms" className="text-white">Terms and Conditions</Link></p>
                                <p className='text-white f-14 fw-light mb-0 me-3'><Link to="/privacy" className="text-white">Privacy Policy</Link></p>
                                <p className="copyright text-white f-14 fw-light mb-0">
                                {(new Date().getFullYear())}{" "} © Lexim AI. 
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
